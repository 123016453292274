<template>
  <!-- 短剧详情页容器 -->
<div class="dj_container">
<br>
<!-- 顶部导航栏 -->
<div class="dj_nav">
  <ul class="dj_ul_top">
        <li class="one"><img src="../../assets/image/image1.png" alt="">&nbsp;雪顶科技</li>
        <li><a href="/">首页</a></li>
        <li><a href="">解决方案</a></li>
        <li><a href="">版权信息平台</a></li>
        <li><a href="">行业动态</a></li>
        <li><a href="">关于我们</a></li>
  </ul>
</div>
<br>
<br>
<br>
<!-- 短剧展示区域 -->
 <div class="dj_container_detail">
  <!-- 展示文本 -->
  <div class="detail_text_container">
    <span class="shouye_text"><a href="/" style="text-decoration: none;"><span class="shouye_text">首页</span></a>&nbsp;></span>&nbsp;<span class="detail_text">详情</span>
  </div>
  <br>
  <!-- 短剧图片 -->
  <div class="img_div1">
  <img src="../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png" alt="" class="short-drama-image1">
 </div>
 <!-- 详情区域 -->
  <div class="dj_content">
    <div class="text">绝世天骄</div>
    <ul class="detail_ul">
      <li>
        <div class="dj_content_text"><span>2024年7月12日上线</span></div>
        <div class="dj_content_text"><span>20集</span></div>
        <div class="dj_content_text"><span>60分钟</span></div>
        <div class="dj_content_text"><span>竖屏</span></div>
      </li>
    </ul>
    <ul class="detail_ul_button">
      <li class="detail_button"><button>男频</button></li>
      <li class="detail_button"><button>都市</button></li>
      <li class="detail_button"><button>情感</button></li>
      <li class="detail_button"><button>老剧</button></li>
    </ul>
    <br>
    <span class="detail_content_title">剧情介绍</span>
    <div class="detail_content">
      《绝世天骄》讲述了主人公从一个普通少年意外获得神秘力量后，不断突破自我，最终成为绝世天骄的传奇故事。剧情跌宕起伏，扣人心弦，从少年的成长历程到面对各种挑战与困境，都充满了戏剧性与感染力‌ 配合激昂的音乐和动感的音效，使观众仿佛置身于一个奇幻的修炼世界故事中的主人公凭借毅力、勇气和智慧，不断突破自我，战胜困难，最终成为一代天骄，激励观众在面对困境时坚定信念，勇往直前...
    </div>
    <br>
    <span class="detail_content_title">授权信息</span>
    <div class="shouquan_info">
      <ul class="shouquan_ul">
        <li>
          <div class="shouquan_title">授权性质：非独家</div>
        </li>
        <li>
          <div class="shouquan_title">可授权范围：全球</div>
        </li>
        <li>
          <div class="shouquan_title">变现方式：不限</div>
        </li>
      </ul>
    </div>
        <button class="connect_jingjiren" @click="showContactModal = true">联系经纪人</button>
        <!-- 弹窗组件 -->
        <div v-if="showContactModal" class="contact-modal">
        <div class="modal-content">
        <!-- 关闭按钮 -->
        <div class="close-button" @click="showContactModal = false">
          <img src="../../assets/image/close-circle.png" alt="图片" style="width: 42px;height: 42px; margin-left: 4px;">
        </div>
        <br>
        <!-- 联系经纪人 -->
         <div class="contact_title"><span>·联系经纪人·</span></div>
         <!-- 展示层 -->
         <div class="show_contact_info">
          <!-- 联系电话 -->
           <div class="contact_phone">联系电话：18779092267</div>
           <!-- 二维码展示区域 -->
            <div class="contact_qrcode"></div>
            <!-- 扫码添加微信 -->
             <div class="contact_add_wechat">扫码添加微信</div>
             <!-- 取消按钮 -->
              <div class="btn_close"><button class="btn_close_button" @click="showContactModal = false">取消</button></div>
          </div>

    </div>
  </div>
  </div>
 </div>
<!-- 相关推荐区域 -->
<div class="related_container">
        <!-- 组合7 -->
        <div class="related_container_7">
        <span><img src="../../assets/image/title_decorate.png" alt="" class="two"></span><span class="three">相关推荐</span><br><br>
        <div class="related_container_img">
          <ul class="short-drama-list1">
                <li class="right_li1">
                    <div class="right_div1">
                       <div class="img_div1">
                        <img src="../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png" alt="" class="short-drama-image1">
                       </div>
                       <p class="img_bottom_text1_1">对我而言危险的他</p>
                       <p class="img_bottom_text1_2">都市传说</p>
                    </div>
                </li>
                <li class="right_li1">
                    <div class="right_div1">
                       <div class="img_div1">
                        <img src="../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png" alt="" class="short-drama-image1">
                       </div>
                       <p class="img_bottom_text1_1">对我而言危险的他</p>
                       <p class="img_bottom_text1_2">都市传说</p>
                    </div>
                </li>
                <li class="right_li1">
                    <div class="right_div1">
                       <div class="img_div1">
                        <img src="../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png" alt="" class="short-drama-image1">
                       </div>
                       <p class="img_bottom_text1_1">对我而言危险的他</p>
                       <p class="img_bottom_text1_2">都市传说</p>
                    </div>
                </li>
                <li class="right_li1">
                    <div class="right_div1">
                       <div class="img_div1">
                        <img src="../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png" alt="" class="short-drama-image1">
                       </div>
                       <p class="img_bottom_text1_1">对我而言危险的他</p>
                       <p class="img_bottom_text1_2">都市传说</p>
                    </div>
                </li>
                <li class="right_li1">
                    <div class="right_div1">
                       <div class="img_div1">
                        <img src="../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png" alt="" class="short-drama-image1">
                       </div>
                       <p class="img_bottom_text1_1">对我而言危险的他</p>
                       <p class="img_bottom_text1_2">都市传说</p>
                    </div>
                </li>
          </ul>
          <ul class="short-drama-list1">
                <li class="right_li1">
                    <div class="right_div1">
                       <div class="img_div1">
                        <img src="../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png" alt="" class="short-drama-image1">
                       </div>
                       <p class="img_bottom_text1_1">对我而言危险的他</p>
                       <p class="img_bottom_text1_2">都市传说</p>
                    </div>
                </li>
                <li class="right_li1">
                    <div class="right_div1">
                       <div class="img_div1">
                        <img src="../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png" alt="" class="short-drama-image1">
                       </div>
                       <p class="img_bottom_text1_1">对我而言危险的他</p>
                       <p class="img_bottom_text1_2">都市传说</p>
                    </div>
                </li>
                <li class="right_li1">
                    <div class="right_div1">
                       <div class="img_div1">
                        <img src="../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png" alt="" class="short-drama-image1">
                       </div>
                       <p class="img_bottom_text1_1">对我而言危险的他</p>
                       <p class="img_bottom_text1_2">都市传说</p>
                    </div>
                </li>
                <li class="right_li1">
                    <div class="right_div1">
                       <div class="img_div1">
                        <img src="../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png" alt="" class="short-drama-image1">
                       </div>
                       <p class="img_bottom_text1_1">对我而言危险的他</p>
                       <p class="img_bottom_text1_2">都市传说</p>
                    </div>
                </li>
                <li class="right_li1">
                    <div class="right_div1">
                       <div class="img_div1">
                        <img src="../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png" alt="" class="short-drama-image1">
                       </div>
                       <p class="img_bottom_text1_1">对我而言危险的他</p>
                       <p class="img_bottom_text1_2">都市传说</p>
                    </div>
                </li>

          </ul>
        </div>
        <br>
</div>
<div>
</div>
</div>
 <!-- 滑块区域 -->
 <div class="float_list2">
  <div class="qiye_wechat">
    <div class="qiye_wechat_center">
      <div class="qiye_wechat_bg"></div>
      <div class="qiye_wechat_text">企业微信</div>
    </div>
  </div>
  <hr class="line">
  <div class="phone_we">
    <div class="phone_we_center">
      <div class="phone_we_bg"></div>
      <div class="phone_we_text">电话咨询</div>
    </div>
  </div>
</div>
<!-- 底部区域 -->
<div class="detail_footer_container">
        <!-- 组合7 -->
         <div class="footer_7">
         <div class="footer_6">
        <span><img src="../../assets/image/image1.png" alt="" class="two2"></span><span class="three2">雪顶科技</span>
        <br>
        <br>
        <br>
        <!-- 公司信息 -->
        <div class="company_info">
          <span>Copyright © 2024 Sohu All Rights Reserved</span><br>
          <span>客服邮箱：kf@vip.sohu.com</span><br>
          <span>增值电信业务经营许可证B1-20090148</span>
        </div>
        <!-- 服务与支持 -->
        <div class="company_service">
            <span>服务与支持</span>
            <br>
            <br>
            <ul class="service-list">
              <li><a href="">版权入驻</a></li>
              <li><a href="">常见问题</a></li>
              <li><a href="">联系我们</a></li>
              <li><a href="">短剧工具</a></li>
              <li><a href="">用户协议</a></li>
              <li><a href="">关于我们</a></li>
            </ul>
        </div>
         </div>
        <!-- 二维码 -->
        <div class="erwei_img">
          <div class="qrcode">
          </div>
          <span>微信服务</span>
         </div>
         <br>
</div>
</div>
</div>
</template>

<script>
export default {
  data() {
    return{
      // 控制弹框的显示与隐藏
      showContactModal: false,
    }
  },
  methods:{
    // 动画效果
 
  },
  mounted(){
   
  }
}
</script>

<style>
*{
  margin: 0;
}
/* 短剧详情整个容器 */
.dj_container{
position: relative;
/* width: 1920px; */
width: auto;
height: auto;
background: rgb(15, 18, 26);
padding-bottom: 20px;
}
.dj_nav{
position: absolute;
width: 1550px;
height: 92px;
left: 0;
right: 0;
top: 0;
/* 居中显示 */
margin: 0 auto;
background: linear-gradient(180.00deg, rgb(0, 0, 0),rgba(0, 0, 0, 0) 100%);
}
.dj_nav li{
  float:left;/*浮动*/
    width: 170px;/*设置一个<li>宽度75px*/
    text-align:center;/*文本居中*/
    color: rgb(255, 255, 255);
    font-family: OPPO Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
}
.dj_ul_top{
list-style: none;/*去除列表格式，消除小黑点*/
position: absolute;
left: 15%;/*距离左侧70px*/
margin-top: 20px;
}
.dj_container_detail{
width: 1110px;
height: 500px;
margin: 0 auto;
padding-left: 128px;
}
.detail_text_container{
width: 200px;
height: 32px;
}
.shouye_text{
color: rgb(255, 255, 255);
font-family: OPPO Sans;
font-size: 24px;
font-weight: 400;
line-height: 32px;
letter-spacing: 0px;
text-align: left;
opacity: 0.6;
}
.detail_text{
color: rgb(255, 255, 255);
font-family: OPPO Sans;
font-size: 24px;
font-weight: 400;
line-height: 32px;
letter-spacing: 0px;
text-align: left;
}
.dj_content{
width:  820px;
height: 450px;
float: right;
}
.dj_content .text{
width: 144px;
height: 54px;
color: rgb(255, 255, 255);
font-family: OPPO Sans;
font-size: 36px;
font-weight: 400;
line-height: 150%;
letter-spacing: 0px;
text-align: left;
}
.detail_ul {
  list-style: none; /* 去掉默认的列表样式 */
  padding: 0;
  margin: 0;
}
.detail_ul li {
  display: flex; /* 使用 Flexbox 布局 */
  align-items: center; /* 垂直居中对齐 */
  gap: 35px; /* 设置每个 div 之间的间距 */
}

.dj_content_text {
position: relative;
padding-right: 10px; /* 为竖线留出空间 */
font-family: OPPO Sans;
color: rgb(255, 255, 255);
font-size: 24px;
font-weight: 400;
line-height: 150%;
letter-spacing: 0px;
text-align: left;
}

.dj_content_text::after {
  content: '|';
  position: absolute;
  right: -17px;
  color: rgb(255, 255, 255); /* 竖线颜色 */
}

.dj_content_text:last-child::after {
  content: ''; /* 最后一个 div 不需要竖线 */
}
.detail_ul_button {
  list-style: none; /* 去掉默认的列表样式 */
  padding: 0;
  margin: 0;
  padding-top: 13px;
  display: flex; /* 使用 Flexbox 布局 */
  gap: 12px; /* 设置每个 li 之间的间距 */
}

.detail_button {
  /* 可以在这里添加其他样式，如果需要 */
}

.detail_button button {
  border-radius: 5px; /* 设置按钮的圆角 */
  background: rgb(44, 121, 255); /* 设置按钮的背景颜色 */
  border: none; /* 去掉默认边框 */
  color: white; /* 设置按钮文字颜色 */
  padding: 5px 10px; /* 设置按钮内边距 */
  cursor: pointer; /* 设置鼠标悬停时的光标样式 */
}

.detail_button button:hover {
  background: rgb(30, 95, 200); /* 设置按钮悬停时的背景颜色 */
}
.detail_content_title{
width: 80px;
height: 30px;
color: rgb(255, 255, 255);
font-family: OPPO Sans;
font-size: 20px;
font-weight: 400;
line-height: 150%;
letter-spacing: 0px;
text-align: left;
padding-top: 10px;
}
.detail_content{
width: 600px;
height: auto;
color: rgb(255, 255, 255);
font-family: OPPO Sans;
font-size: 16px;
font-weight: 400;
line-height: 150%;
letter-spacing: 0px;
text-align: left;
opacity: 0.7;
}
.shouquan_ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex; /* 使用 Flexbox 布局 */
  gap: 20px; /* 设置子元素之间的间距 */
}

.shouquan_ul li {
  display: flex;
  align-items: center;
}

.shouquan_title {
color: rgb(255, 255, 255);
font-family: OPPO Sans;
font-size: 16px;
font-weight: 400;
line-height: 150%;
letter-spacing: 0px;
text-align: left;
opacity: 0.7;
}
.connect_jingjiren{
  width: 152px;
height: 50px;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
border-radius: 12px;
background: rgb(231, 142, 0);
color: rgb(255, 255, 255);
font-family: OPPO Sans;
font-size: 18px;
font-weight: 400;
line-height: 150%;
letter-spacing: 0px;
float: right;
}
/* 相关推荐 */
.related_container{
width: 1110px;
height: 800px;
margin: 0 auto;
padding-left: 128px;
}
.related_container_7{
  /* 组合 7 */
width: 222px;
height: 47px;
}
.related_container_img{
  width: 1110px;
  height: 700px;
  margin-left: 2px;
}
.short-drama-list1{
list-style-type: none;
display: flex;
gap: 19px;
padding: 0;
margin: 0;
  /*  */
}
.short-drama-item1 {
width: 23%; /* 每个元素占23%，加上间距正好4个元素一行 */
box-sizing: border-box; /* 确保内边距和边框不会增加元素的总宽度 */
}
/* 图片容器 */
.img_div1{
width: 206px;
height: 280px;
position: relative;
display: inline-block;

}
/* 图片大小 */
.short-drama-image1{
width: 206px;
height: 280px;
object-fit: cover; /* 确保图片覆盖整个容器 */
}
/* 短剧图下方展示文本 */
.img_bottom_text1_1{
width: 180px;
height: 30px;
color: rgb(255, 255, 255);
font-family: OPPO Sans;
font-size: 20px;
font-weight: 400;
line-height: 150%;
letter-spacing: 0px;
text-align: left;
white-space: nowrap;
}
.img_bottom_text1_2{
width: 56px;
height: 21px;
color: rgb(255, 255, 255);
font-family: OPPO Sans;
font-size: 14px;
font-weight: 400;
line-height: 150%;
letter-spacing: 0px;
text-align: left;
opacity: 0.6;
white-space: nowrap;
}
.detail_footer_container{
/* 组合 52 */
width: 1110px;
height: 372px;
margin: 0 auto;
background: url('../../assets/image/footer_ingo_back.png');
background-size: cover;
/* 图片宽度 */
background-position: center;
position: relative;
padding-left: 130px;
}
.float_list2{
width: 104px;
height: 212px;
overflow: hidden;
float: right;
margin-top: -700px;
z-index: 1000;
border-radius: 18px 0px 0px 18px;
/* 背景阴影 */
background-color: rgba(255, 255, 255, 0.1);
}
/* 弹框样式 */
.contact-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
width: 484px;
height: 476px;
box-sizing: border-box;
border: 8px solid rgb(65, 88, 128);
border-radius: 31px;
background: linear-gradient(180.00deg, rgb(18, 28, 55),rgb(9, 12, 20) 100%);
}
.close-button {
cursor: pointer;
font-size: 20px;
width: 48px;
height: 48px;
float: right;
margin-top: 15px;
}
.contact_title{
width: 192px;
height: 48px;
color: rgb(255, 255, 255);
font-family: OPPO Sans;
font-size: 27px;
font-weight: 400;
line-height: 150%;
letter-spacing: 0px;
margin: 0 auto;
}
.show_contact_info{
width: 430px;
height: 385px;
margin: 0 auto;
border-radius: 28px;
background: linear-gradient(180.00deg, rgba(255, 255, 255,0.12),rgba(255, 255, 255, 0) 100%);
}
.contact_phone{
width: 290px;
height: 36px;
color: rgb(255, 255, 255);
font-family: OPPO Sans;
font-size: 24px;
font-weight: 400;
line-height: 150%;
letter-spacing: 0px;
text-align: left;
margin: 0 auto;
}
.contact_qrcode{
width: 206px;
height: 206px;
border-radius: 14px;
background: url("../../assets/image/xd_wx_code.png");
margin: 0 auto;
margin-top: 20px;
}
.contact_add_wechat{
width: 144px;
height: 36px;
color: rgb(255, 255, 255);
font-family: OPPO Sans;
font-size: 24px;
font-weight: 400;
line-height: 150%;
letter-spacing: 0px;
text-align: left;
opacity: 0.8;
margin: 0 auto;
margin-top: 10px;
}
.btn_close{
width: 152px;
height: 50px;
margin: 0 auto;
margin-top: 10px;
}
.btn_close_button{
width: 152px;
height: 50px;
display: flex;
align-items: center;
justify-content: center;
color: rgb(255, 255, 255);
font-family: OPPO Sans;
font-size: 18px;
font-weight: 400;
line-height: 150%;
letter-spacing: 0px;
text-align: left;
border-radius: 12px;
background: rgb(44, 121, 255);
}
</style>