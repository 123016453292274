import Vue from "vue";
import Router from "vue-router";
import IndexVue from "@/views/IndexVue.vue";
import DJDetailsVue from "@/views/Details/DJDetailsVue.vue";
import AllContentVue from "@/views/AllContent/AllContentVue.vue";
Vue.use(Router);
export default new Router({
    mode:'history',
    routes:[
        {
            path:'/',
            name:'index',
            component:IndexVue
        },
        // 版权详情
        {
            path:'/playlet/',
            name:'playlet',
            component:DJDetailsVue
        },
        // 全部内容
        {
            path:'/allcontent',
            name:'allcontent',
            component:AllContentVue
        }
    ],
    // 添加导航守卫
    scrollBehavior(to, from, savedPosition) {
        // 如果有保存的滚动位置，返回该位置
        if (savedPosition) {
          return savedPosition;
        } else {
        // 否则返回顶部
          return { x: 0, y: 0 };
        }
        
        
        
  }

})