<template>
<div class="container1">
<!--头部导航栏 -->
<div class="nav" >
    <ul class="ul_top">
        <li class="one"><img src="../assets/image/image1.png" alt="">&nbsp;雪顶科技</li>
        <li><a href="#">首页</a></li>
        <li><a href="#">解决方案</a></li>
        <li><a href="#">版权信息平台</a></li>
        <li><a href="#">行业动态</a></li>
        <li><a href="#">关于我们</a></li>
    </ul>
</div>
<!-- 短剧bannner区域 -->
<div class="banner"></div>

<!-- 中间区域 -->
<div class="container2">
<!-- 短剧工具区域 -->
<div class="tools">
        <!-- 组合7 -->
         <div class="tools_7">
         <div class="tools_6">
        <span><img src="../assets/image/title_decorate.png" alt="" class="two"></span><span class="three">短剧工具</span>
        <br>
        <br>
        <p class="four">提供大量的短剧产品和服务、为推动短剧业务提供解决方案</p>
         </div>
         <br>
         <ul class="box-list">
    <li class="box-item">
      <img src="../assets/image/model1.png" alt="图标1" class="box-icon">
    </li>
    <li class="box-item">
      <img src="../assets/image/model2.png" alt="图标2" class="box-icon">
    </li>
    <li class="box-item">
      <img src="../assets/image/model3.png" alt="图标3" class="box-icon">
    </li>
    <li class="box-item">
      <img src="../assets/image/model4.png" alt="图标4" class="box-icon">
    </li>
    <li class="box-item">
      <img src="../assets/image/model5.png" alt="图标4" class="box-icon">
    </li>
  </ul>
</div>
</div>
<!-- 滑块区域 -->
<div class="float_list1">
  <div class="qiye_wechat">
    <div class="qiye_wechat_center">
      <div class="qiye_wechat_bg"></div>
      <div class="qiye_wechat_text">企业微信</div>
    </div>
  </div>
  <hr class="line">
  <div class="phone_we">
    <div class="phone_we_center">
      <div class="phone_we_bg"></div>
      <div class="phone_we_text">电话咨询</div>
    </div>
  </div>
</div>
<br>
<!-- 短剧版权区域 -->
<div class="banquan">
        <!-- 组合7 -->
         <div class="tools_7">
         <div class="tools_6">
        <span><img src="../assets/image/title_decorate.png" alt="" class="two"></span>
        <span class="three">短剧版权内容</span>
        <br>
        <br>
         <p class="four">最全的短剧作品内容，全国版权剧库，版权溯源可查</p>
           <router-link to="/allcontent" class="more-info" style="text-decoration: none;"><p class="more-info">查看更多></p></router-link>
         </div>

        </div>
        <!--  短剧版权左边导航栏 -->
         <div class="left_container">
            <!-- 展示导航栏 -->
             <ul class="left_ul">
                 <li class="left_li">
                    <div class="hover-highlight">
                        <span class="left_span">首发新剧</span><img src="../assets/image/turn_right2.png" alt="" class="right">
                        <p class="left_p">首发新剧，重磅出击</p>
                    </div>
                 </li>
                 <li class="left_li">
                    <div class="hover-highlight">
                        <span class="left_span">爆款老剧</span><img src="../assets/image/turn_right2.png" alt="" class="right">
                        <p class="left_p">爆款老剧依然给力</p>
                    </div>
                 </li>
                 <li class="left_li">
                    <div class="hover-highlight">
                        <span class="left_span">优质剧</span><img src="../assets/image/turn_right2.png" alt="" class="right">
                        <p class="left_p">优质内容是短剧市场竞争的核心</p>
                    </div>
                 </li>
                 <li class="left_li">
                    <div class="hover-highlight">
                        <span class="left_span">备案剧</span><img src="../assets/image/turn_right2.png" alt="" class="right">
                        <p class="left_p">狂飙的短剧，终被套上缰绳</p>
                    </div>
                 </li>
             </ul>
         </div>
         <!-- 右侧展示短剧区域 -->
          <div class="right_container1">
            <ul class="short-drama-list1">
              <li class="right_li1" v-for="item in duanjuList" :key="item.id">
                      <div class="right_div1">
                      <div class="img_div1">
                        <img :src="item.img" alt="" class="short-drama-image1">
                        <span class="img_bottom_text1_3">{{item.count}}集</span>
                       </div>
                       <p class="img_bottom_text1_1">{{item.name }}</p>
                       <p class="img_bottom_text1_2">{{ item.type }}</p>
                    </div>
              </li>
                <br>
            </ul>
            <br>
            <ul class="short-drama-list2">
                <li class="right_li2">
                    <div class="right_div2">
                       <div class="img_div2">
                        <img src="../assets/image/model2.png" alt="图片" class="short-drama-image2">
                        <span class="img_bottom_text2_3">20集</span>
                       </div>
                       <p class="img_bottom_text2_1">对我而言危险的他...</p>
                       <p class="img_bottom_text2_2">都市传说</p>
                    </div>
                </li>
                <li class="right_li2">
                    <div class="right_div2">
                      <div class="img_div2">
                        <img src="../assets/image/model2.png" alt="" class="short-drama-image2">
                        <span class="img_bottom_text2_3">20集</span>
                       </div>
                       <p class="img_bottom_text2_1">厌春宫</p>
                       <p class="img_bottom_text2_2">古代言情</p>
                    </div>
                </li>
                <li class="right_li2">
                    <div class="right_div2">
                      <div class="img_div2">
                        <img src="../assets/image/model2.png" alt="" class="short-drama-image2">
                        <span class="img_bottom_text2_3">20集</span>
                       </div>
                       <p class="img_bottom_text2_1">科研大佬穿成七零...</p>
                       <p class="img_bottom_text2_2">现代言情</p>
                    </div>
                </li>
                <li class="right_li2">
                    <div class="right_div2">
                      <div class="img_div2">
                        <img src="../assets/image/model2.png" alt="" class="short-drama-image2">
                        <span class="img_bottom_text2_3">20集</span>
                       </div>
                       <p class="img_bottom_text2_1">面甜心黑小白菜，重...</p>
                       <p class="img_bottom_text2_2">现代言情</p>
                    </div>
                </li>
                <br>
            </ul>
        </div>
</div>
<!-- 小说版权区域 -->
<div class="banquan">
        <!-- 组合7 -->
         <div class="tools_7">
         <div class="tools_6">
        <span><img src="../assets/image/title_decorate.png" alt="" class="two"></span>
        <span class="three">小说版权内容</span>
        <br>
        <br>
         <p class="four">最全的短剧作品内容，全国版权剧库，版权溯源可查</p>
         <router-link to="/allcontent" class="more-info" style="text-decoration: none;"><p class="more-info">查看更多></p></router-link>
         </div>

        </div>
        <!--  小说版权左边导航栏 -->
         <div class="left_container">
            <!-- 展示导航栏 -->
             <ul class="left_ul">
                 <li class="left_li">
                    <div class="hover-highlight">
                        <span class="left_span">年度精选</span><img src="../assets/image/turn_right2.png" alt="" class="right">
                        <p class="left_p">首发新剧，重磅出击</p>
                    </div>
                 </li>
                 <li class="left_li">
                    <div class="hover-highlight">
                        <span class="left_span">雪顶推荐</span><img src="../assets/image/turn_right2.png" alt="" class="right">
                        <p class="left_p">爆款老剧依然给力</p>
                    </div>
                 </li>
                 <li class="left_li">
                    <div class="hover-highlight">
                        <span class="left_span">最新上架</span><img src="../assets/image/turn_right2.png" alt="" class="right">
                        <p class="left_p">优质内容是短剧市场竞争的核心</p>
                    </div>
                 </li>
                 <li class="left_li">
                    <div class="hover-highlight">
                        <span class="left_span">畅销小说</span><img src="../assets/image/turn_right2.png" alt="" class="right">
                        <p class="left_p">狂飙的短剧，终被套上缰绳</p>
                    </div>
                 </li>
             </ul>
         </div>
         <!-- 右侧展示小说区域 -->
          <div class="right_container1">
            <ul class="short-drama-list1">
                <li class="right_li1">
                    <div class="right_div1">
                       <div class="img_div1">
                        <img src="../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png" alt="" class="short-drama-image1">
                        <span class="img_bottom_text1_3">20集</span>
                       </div>
                       <p class="img_bottom_text1_1">对我而言危险的他</p>
                       <p class="img_bottom_text1_2">都市传说</p>
                    </div>
                </li>
                <li class="right_li1">
                    <div class="right_div1">
                      <div class="img_div1">
                        <img src="../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png" alt="" class="short-drama-image1">
                        <span class="img_bottom_text1_3">20集</span>
                       </div>
                       <p class="img_bottom_text1_1">心跳原计划</p>
                       <p class="img_bottom_text1_2">仙侠奇缘</p>
                    </div>
                </li>
                <li class="right_li1">
                    <div class="right_div1">
                      <div class="img_div1">
                        <img src="../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png" alt="" class="short-drama-image1">
                        <span class="img_bottom_text1_3">20集</span>
                       </div>
                       <p class="img_bottom_text1_1">不说再见</p>
                       <p class="img_bottom_text1_2">总裁豪门</p>
                    </div>
                </li>
                <li class="right_li1">
                    <div class="right_div1">
                      <div class="img_div1">
                        <img src="../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png" alt="" class="short-drama-image1">
                        <span class="img_bottom_text1_3">20集</span>
                       </div>
                       <p class="img_bottom_text1_1">对我而言危险的他</p>
                       <p class="img_bottom_text1_2">都市传说</p>
                    </div>
                </li>
                <br>
            </ul>
            <br>
            <ul class="short-drama-list1">
                <li class="right_li1">
                    <div class="right_div1">
                       <div class="img_div1">
                        <img src="../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png" alt="" class="short-drama-image1">
                        <span class="img_bottom_text1_3">20集</span>
                       </div>
                       <p class="img_bottom_text1_1">对我而言危险的他</p>
                       <p class="img_bottom_text1_2">都市传说</p>
                    </div>
                </li>
                <li class="right_li1">
                    <div class="right_div1">
                      <div class="img_div1">
                        <img src="../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png" alt="" class="short-drama-image1">
                        <span class="img_bottom_text1_3">20集</span>
                       </div>
                       <p class="img_bottom_text1_1">心跳原计划</p>
                       <p class="img_bottom_text1_2">仙侠奇缘</p>
                    </div>
                </li>
                <li class="right_li1">
                    <div class="right_div1">
                      <div class="img_div1">
                        <img src="../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png" alt="" class="short-drama-image1">
                        <span class="img_bottom_text1_3">20集</span>
                       </div>
                       <p class="img_bottom_text1_1">不说再见</p>
                       <p class="img_bottom_text1_2">总裁豪门</p>
                    </div>
                </li>
                <li class="right_li1">
                    <div class="right_div1">
                      <div class="img_div1">
                        <img src="../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png" alt="" class="short-drama-image1">
                        <span class="img_bottom_text1_3">20集</span>
                       </div>
                       <p class="img_bottom_text1_1">对我而言危险的他</p>
                       <p class="img_bottom_text1_2">都市传说</p>
                    </div>
                </li>
                <br>
            </ul>
        </div>
</div>
<!-- 行业动态区域 -->
<div class="industry_container">
        <!-- 组合7 -->
         <div class="tools_7">
         <div class="tools_6">
        <span><img src="../assets/image/title_decorate.png" alt="" class="two"></span><span class="three">行业动态</span>
        <br>
        <br>
        <p class="four">聚焦行业热点，探讨新走向</p>
        <a href="" style="text-decoration: none;"><p class="more-info">查看更多></p></a>
        </div>
</div>
<br>
<!-- 新闻列表展示区域 -->
  <div class="industry_back">
    <div class="news_info">
      <ul class="news_list">
        <li class="news_info_li">
          <span>·雪顶短剧重磅出新</span>&nbsp;&nbsp;&nbsp;<button class="btn">最新</button><span><div class="more">详情></div></span>
          <hr class="hr_line">
        </li>
        <li class="news_info_li">
          <span>·广电总局要求加强对霸总微短剧管理</span><span><div class="more">详情></div></span>
          <hr class="hr_line">
        </li>
        <li class="news_info_li">
          <span>·莫言强忍不看短剧，周鸿祎下场拍短剧...</span><span><div class="more">详情></div></span>
          <hr class="hr_line">
        </li>
        <li class="news_info_li">
          <span>·海报时评丨整治霸总短剧，呼唤理性回归</span><span><div class="more">详情></div></span>
          <hr class="hr_line">
        </li>
        <li class="news_info_li">
          <span>·雪顶短剧重磅出新</span><span><div class="more">详情></div></span>
        </li>
      </ul>
    </div>
  </div>
</div>
<br>

<!-- 底部区域 -->
<div class="footer_container">
        <!-- 组合7 -->
         <div class="footer_7">
         <div class="footer_6">
        <span><img src="../assets/image/image1.png" alt="" class="two2"></span><span class="three2">雪顶科技</span>
        <br>
        <br>
        <br>
        <!-- 公司信息 -->
        <div class="company_info">
          <span>Copyright © 2024 Sohu All Rights Reserved</span><br>
          <span>客服邮箱：kf@vip.sohu.com</span><br>
          <span>增值电信业务经营许可证B1-20090148</span>
        </div>
        <!-- 服务与支持 -->
        <div class="company_service">
            <span>服务与支持</span>
            <br>
            <br>
            <ul class="service-list">
              <li><a href="">版权入驻</a></li>
              <li><a href="">常见问题</a></li>
              <li><a href="">联系我们</a></li>
              <li><a href="">短剧工具</a></li>
              <li><a href="">用户协议</a></li>
              <li><a href="">关于我们</a></li>
            </ul>
        </div>
         </div>
        <!-- 二维码 -->
        <div class="erwei_img">
          <div class="qrcode">
          </div>
          <span>微信服务</span>
         </div>
         <br>
</div>
</div>
</div>
</div>
</template>

<script>
// 引入axios
// import axios from 'axios'
export default {
    mounted() {
    this.donghua()
    //this.getAllDjBanQuan(
  },
  data(){
    return{
      // 短剧版权列表
      duanjuList:[
        {
          id:1,
          name:'心跳原计划',
          type:'都市传说',
          count:20,
          img: require('../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png')
        },
        {
          id:2,
          name:'难忘的他',
          type:'都市爱情',
          count:45,
          img: require('../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png')
        },
        {
          id:3,
          name:'心跳原计划',
          type:'都市传说',
          count:20,
          img: require('../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png')
        },
        {
          id:4,
          name:'心跳原计划',
          type:'都市传说',
          count:20,
          img: require('../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png')
        }],
      // 小说版权列表
      xiaoshuoList:[],
    }
  },
  methods:{
    // 动画效果
    donghua(){
    const links = document.querySelectorAll('.nav a');
    links.forEach(link => {
      link.addEventListener('click', () => {
        link.classList.add('click-effect');
        // 移除动画类，以便下次点击时可以再次触发动画
        setTimeout(() => {
          link.classList.remove('click-effect');
        }, 300);
      });
    });
    },
    // 点击li条跳转到详情页面
    goToDetails(id){
      this.$router.push({
        path:'/details',
        query:{
          id:id
        }
      })
    }
  }
}

</script>

<style src="../assets/css/index.css">

</style>